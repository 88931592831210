import React from "react";

import Layout from "../layouts/ja";
import SEO from "../components/seo";

import { AccountBlocked } from "../components/TWComponents/AccountBlocked";

const AccountBlockedPage = (props) => {
  return (
    <Layout location={props.location} showBookNowButton={false}>
      <SEO
        title="Account Deactivated"
        keyword="Account Deactivated"
        decription="Account Deactivated"
      />
      <AccountBlocked
        title="アカウントが無効になりました"
        subDesciption="過去 12 か月以内にアクティビティがなかったため、あなたのアカウントは無効になりました。
        "
        decription='アカウントを再度有効にするか、サポートが必要な場合は <a href="mailto:info@ayanarewards.com?subject=AYANA%20Rewards%20Account%20Reactivation">info@ayanarewards.com</a> までご連絡ください。'
        button="Account Deactivated"
      />
    </Layout>
  );
};

export default AccountBlockedPage;
