import React from "react";
import "tailwindcss/tailwind.css";
import "../../style/twform.css";

export const AccountBlocked = ({ title,  subDesciption, decription }) => {
  return (
    <div className="tw-antialiased lg:tw-max-w-[400px] lg:tw-mx-auto lg:tw-border-[#C2C8D0]/30 lg:tw-border lg:tw-border-solid lg:tw-rounded-md lg:tw-mt-[64px] !tw-font-ayana">
      <div className="tw-py-[56px] tw-px-[40px]">
        <h2 className="tw-text-[22px] tw-leading-[33px] !tw-font-bold tw-text-center !tw-font-ayana !tw-text-[#3E3A39] tw-py-3">
        {title}
        </h2>
        <div className="tw-flex tw-flex-col tw-gap-6">
          <p className="tw-text-sm tw-leading-5 !tw-font-ayana tw-m-0 !tw-text-[#3E3A39]">
           {subDesciption}
          </p>
          <p className="tw-text-sm tw-leading-5 !tw-font-ayana tw-m-0 !tw-text-[#3E3A39]" dangerouslySetInnerHTML={{__html: decription}}>
          </p>
        </div>
      </div>
    </div>
  );
};
